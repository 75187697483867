var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "monitor-template",
    {
      ref: "monitorRef",
      staticClass: "cleaner",
      attrs: { mapPageParams: _vm.mapPageParams },
      on: {
        mapPageClick: _vm.handleMapPageClick,
        mapToolClick: _vm.handleMapToolClick,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "left" }, slot: "left" },
        [
          _c(
            "vxe-modal",
            {
              staticClass: "my-modal",
              attrs: {
                title: "保洁人员",
                width: "390px",
                height: "90%",
                "show-zoom": "",
                resize: "",
                "lock-view": false,
                mask: false,
                position: { top: "5%", left: "8%" },
              },
              on: { close: _vm.handleCloseLeftModal },
              model: {
                value: _vm.showLeftModal,
                callback: function ($$v) {
                  _vm.showLeftModal = $$v
                },
                expression: "showLeftModal",
              },
            },
            [
              [
                _c(
                  "div",
                  { staticClass: "vechicle-center" },
                  [
                    _c("object-tree", {
                      ref: "vehicleTree",
                      staticStyle: { height: "50%" },
                      attrs: {
                        "tree-data": _vm.treeData,
                        "place-holder": "输入工牌编号进行搜索",
                        "object-title": "工牌",
                        "cache-key": "CleanerTree",
                        "object-icon": "userIcon",
                      },
                      on: {
                        select: _vm.clickCleanerNode,
                        check: _vm.checkCleanerNode,
                      },
                    }),
                    _c("a-divider", {
                      staticStyle: { height: "1px", margin: "5px 0" },
                    }),
                    _c("a-row", {
                      attrs: { type: "flex", justify: "space-around" },
                    }),
                    _c("a-divider", {
                      staticStyle: { height: "1px", margin: "5px 0" },
                    }),
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "a-tabs",
                              {
                                attrs: { type: "card", size: "small" },
                                on: { change: _vm.handleTabChange },
                              },
                              [
                                _c(
                                  "a-tab-pane",
                                  { key: "1", attrs: { tab: "实时状态" } },
                                  [
                                    _c("MapCleanerInfoWindow", {
                                      attrs: {
                                        "map-obj": _vm.MapObj,
                                        objectInfo: _vm.objectInfo,
                                        "show-footer": false,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-tab-pane",
                                  { key: "2", attrs: { tab: "历史轨迹" } },
                                  [
                                    _c("map-history-track", {
                                      attrs: {
                                        monitorRef: _vm.$refs.monitorRef,
                                        params: _vm.mapPageParams,
                                        historyTrackPointsArray:
                                          _vm.mapPageParams
                                            .historyTrackPointsArray,
                                      },
                                      on: {
                                        onHistoryTrackSearch:
                                          _vm.handleHistoryTrackSearch,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm.showSettingInfo
        ? _c(
            "div",
            {
              staticClass: "map-control-custom",
              attrs: { slot: "map-control-custom" },
              slot: "map-control-custom",
            },
            [
              _c("div", { staticClass: "content" }, [
                _c("span", [_vm._v("12")]),
                _c("span", [_vm._v("/21")]),
              ]),
              _c("div", { staticClass: "label" }, [
                _vm._v("当前在线(在线人数/总人数)"),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "map-control-custom-right",
          attrs: { slot: "map-control-custom-right" },
          slot: "map-control-custom-right",
        },
        [
          _c("div", { staticClass: "tool" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    _vm.showSettingInfo = !_vm.showSettingInfo
                  },
                },
              },
              [_c("a-icon", { attrs: { type: "setting" } })],
              1
            ),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.currentTime))]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }