<template>
  <monitor-template ref="monitorRef" class="cleaner" :mapPageParams="mapPageParams" @mapPageClick="handleMapPageClick" @mapToolClick="handleMapToolClick">
    <div slot="left">
      <!-- 左侧弹窗 -->
      <!-- 固定宽度体验更佳 -->
      <vxe-modal
        v-model="showLeftModal"
        title="保洁人员"
        width="390px"
        height="90%"
        class="my-modal"
        @close="handleCloseLeftModal"
        show-zoom
        resize
        :lock-view="false"
        :mask="false"
        :position="{ top: '5%', left: '8%' }"
      >
        <template>
          <!-- 树形结构:保洁人员数据 -->
          <div class="vechicle-center">
            <object-tree
              style="height: 50%"
              ref="vehicleTree"
              :tree-data="treeData"
              place-holder="输入工牌编号进行搜索"
              object-title="工牌"
              cache-key="CleanerTree"
              object-icon="userIcon"
              @select="clickCleanerNode"
              @check="checkCleanerNode"
            />
            <a-divider style="height: 1px; margin: 5px 0" />
            <!-- 操作按钮区 -->
            <a-row type="flex" justify="space-around"> </a-row>
            <a-divider style="height: 1px; margin: 5px 0" />
            <a-row>
              <a-col :span="24">
                <a-tabs type="card" size="small" @change="handleTabChange">
                  <a-tab-pane key="1" tab="实时状态">
                    <MapCleanerInfoWindow :map-obj="MapObj" :objectInfo="objectInfo" :show-footer="false" />
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="历史轨迹">
                    <!-- 传入monitorRef是为了操作地图 -->
                    <map-history-track
                      :monitorRef="$refs.monitorRef"
                      :params="mapPageParams"
                      :historyTrackPointsArray="mapPageParams.historyTrackPointsArray"
                      @onHistoryTrackSearch="handleHistoryTrackSearch"
                    />
                  </a-tab-pane>
                </a-tabs>
              </a-col>
            </a-row>
          </div>
        </template>
      </vxe-modal>
    </div>
    <!-- 地图上自定义显示区域 -->
    <div slot="map-control-custom" class="map-control-custom" v-if="showSettingInfo">
      <div class="content"><span>12</span><span>/21</span></div>
      <div class="label">当前在线(在线人数/总人数)</div>
    </div>
    <!-- 地图上右侧自定义显示区域 -->
    <div slot="map-control-custom-right" class="map-control-custom-right">
      <div class="tool">
        <span @click="showSettingInfo = !showSettingInfo"> <a-icon type="setting" /></span> <span>{{ currentTime }}</span>
      </div>
    </div>
  </monitor-template>
</template>
<script>
// import { getDeviceTree } from '@/api/iot/raDevice'
// import { getCleanerData } from './cleanerData.js'
import MonitorTemplate from '../../../monitorTemplate'
import MapHistoryTrack from '../../../monitorComponents/mapHistoryTrack'
// 车辆树
import ObjectTree from '../../../monitorComponents/objectTree'
import MapCleanerInfoWindow from '../../../monitorComponents/mapInfoWindow/cleaner/department/object'
import { listDeviceEmployee } from '@/api/jt808/deviceEmployee'
// import { employeeAccountTree } from '@/api/iot/employeeAccount'
import { queryDeviceStatus } from '@/api/jt808/deviceStatus'
import { Tabs, Collapse } from 'ant-design-vue'
import { queryHistoryCoordinates } from '@/api/jt808/deviceLocation'
import { VehiclePlayIcon } from '@/views/monitor/monitorComponents/utils/mapUtil'
export default {
  name: 'MonitorCleaner',
  components: {
    MonitorTemplate,
    ObjectTree,
    MapHistoryTrack,
    MapCleanerInfoWindow,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ACollapse: Collapse,
    ACollapsePanel: Collapse.Panel
  },
  data() {
    return {
      mapPageParams: {
        objectId: 'deviceId',
        timingUpdate: true,
        showHeader: true,
        initCountDown: 20,
        infoWindowTitle: 'name',
        // 显示轨迹明细按钮
        showMapTrackDetailButton: false,
        moduleKey: 'cleaner',
        // 地图数据源
        mapDataSource: 'real',
        // 地图上左侧按钮显示文字
        leftModalTitle: '保洁人员',
        // 地图上右侧按钮显示文字
        rightModalTitle: '',
        // 保洁人员显示图标
        objectIcon: { url: require('@/assets/images/demo/cleaner.png'), size: { width: 50, height: 60 } },
        // 保洁人员当前位置分布及相关人员信息
        objectPoints: [],
        historyTrackPointsArray: [],
        lushuConfig: {
          playIcon: VehiclePlayIcon,
          // 不需要控制车辆方向
          enableRotation: false,
          // 轨迹播放时ICON上方显示的文字
          defaultContent: '',
          // 图标大小
          iconSize: { width: 48, height: 48 },
          // 图标的定位点相对于图标左上角的偏移值
          anchorSize: { width: 24, height: 24 }
        },
        // 是否显示地图轨迹明细
        showMapTrackDetail: false
      },
      showLeftModal: true,
      treeData: [],
      // 当前选中的设备Id
      currentSelectedId: '',
      // 当前打勾的设备列表
      currentCheckedList: [],
      // 人员状态信息
      cleanerInfoArray: [],
      currentActiveTabKey: '1',
      // 当前地图坐标对应的地址
      currentMapAddress: '',
      // 当前选中的物体对象
      objectInfo: null,
      // 设备人员数据
      deviceEmployeeArray: [],
      // 地图对象
      MapObj: null,
      // 显示设置信息
      showSettingInfo: false,
      // 当前时间
      currentTime: ''
    }
  },
  created() {
    this.initCleanerTree()
    this.initTimer()
  },
  methods: {
    initTimer() {
      this.timer = setInterval(() => {
        this.currentTime = this.parseDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss')
      }, 1000)
    },
    handleTabChange(activeKey) {
      this.currentActiveTabKey = activeKey
      if (activeKey === '2') {
        // 历史轨迹地图
        this.mapPageParams.mapDataSource = 'track'
        this.mapPageParams.showMapTrackDetailButton = true
      } else {
        // 实时状态地图
        this.mapPageParams.mapDataSource = 'real'
        this.mapPageParams.showMapTrackDetailButton = false
        this.mapPageParams.showMapTrackDetail = false
      }

      if (activeKey === '1') {
        // 重置轨迹播放数据
        this.mapPageParams.historyTrackPointsArray = []
        this.handleMapTrackPlayStop()
        // 更新数据但不需要重新渲染
        this.getData()
      }
    },
    handleCloseLeftModal() {
      this.showLeftModal = false
    },
    // initCleanerTree() {
    //   console.log('employeeAccountTree')
    //   employeeAccountTree({}).then((response) => {
    //     // const treeData = []
    //     this.deviceEmployeeArray = []
    //     this.treeData = response.data
    //     this.setDeviceEmployeeArray(response.data)
    //   })
    // },
    initCleanerTree() {
      listDeviceEmployee({}).then((response) => {
        const treeData = []
        this.deviceEmployeeArray = response.rows
        this.deviceEmployeeArray.forEach((p) => {
          treeData.push({
            id: 't-1-' + p.deviceId,
            label: p.deviceId + '(' + p.name + ')'
          })
        })
        this.treeData = [{ id: '0', label: '所有工牌', children: treeData }]
      })
    },
    setDeviceEmployeeArray(data) {
      if (!data || data.length === 0) {
        return false
      }
      data.forEach((p) => {
        if (p && p.id.indexOf('t-1-') >= 0) {
          this.deviceEmployeeArray.push({
            deviceId: p.id.substr(4),
            name: p.label
          })
        }
        if (p.children && p.children.length > 0) {
          this.setDeviceEmployeeArray(p.children)
        }
      })
    },
    // 点击选中设备，当前选中Id，当前打勾设备列表
    clickCleanerNode(selectedId, checkedList, isSameList) {
      console.log('clickCleanerNode', selectedId)
      // 手动控制是否显示弹窗信息
      this.$refs.monitorRef.mapShowObjectInfo(selectedId, isSameList)
      this.currentSelectedId = selectedId
      // 先判断打勾列表是否发生变化，如果不变，则不重新加载后台数据
      if (this.currentCheckedList.toString() === checkedList.toString()) {
        // console.log('打勾列表不变')
        this.toSelectObject()
        return
      }

      this.currentCheckedList = [...checkedList]
      this.getData()
    },
    // 选中物体
    toSelectObject() {
      this.objectInfo = this.mapPageParams.objectPoints.find((p) => p.deviceId === this.currentSelectedId)
      if (!this.objectInfo) {
        if (this.currentSelectedId) {
          this.$warningEx('找不到当前工牌位置信息-' + this.currentSelectedId)
        }
      }
      if (this.mapPageParams.mapDataSource === 'track') {
        this.mapPageParams.currentTrackObject = this.objectInfo
      }
    },
    // 打勾选择设备，当前打勾的设备列表，当前选中的设备Id
    checkCleanerNode(checkedList, selectedId, isSameList) {
      console.log('checkCleanerNode', checkedList)
      this.currentCheckedList = checkedList
      this.currentSelectedId = selectedId
      this.$refs.monitorRef.mapShowObjectInfo(selectedId, isSameList)
      this.getData()
    },
    getData() {
      console.log('getData')
      // 只有在实时状态页时才需要更新数据
      if (this.currentActiveTabKey !== '1') {
        const obj = this.mapPageParams.objectPoints.find((p) => p.deviceId === this.currentSelectedId)
        if (obj) {
          return
        }
      }
      // 如果没有打勾的设备，则地图上不显示设备
      if (this.currentCheckedList.length === 0) {
        this.objectInfo = null
        this.mapPageParams.objectPoints = []
        return
      }

      // 如果没有选中设备，则地图上不显示设备的气泡
      if (!this.currentSelectedId) {
        this.objectInfo = null
      }
      queryDeviceStatus(this.currentCheckedList).then((res) => {
        if (res.data) {
          const data = res.data.map((p) => {
            return { ...p, lat: p.coordinates[1], lng: p.coordinates[0], ...this.deviceEmployeeArray.find((t) => t.deviceId === p.deviceId) }
          })
          // 设备详细信息列表
          this.mapPageParams.objectPoints = data
          this.objectInfo = data.find((p) => p.deviceId === this.currentSelectedId)
        }
      })
    },
    handleMapPageClick(eventName, eventArgs) {
      if (eventName === 'showLeftModal') {
        this.showLeftModal = eventArgs
      } else if (eventName === 'showMapTrackDetail') {
        this.mapPageParams.showMapTrackDetail = eventArgs
      } else if (eventName === 'selectObjectInfo') {
        // 地图上选中物体
        this.objectInfo = { ...eventArgs }
        this.currentSelectedId = this.objectInfo.deviceId
      } else if (eventName === 'setMapObj') {
        this.MapObj = eventArgs
      }
    },
    // 地图上方工具栏点击事件
    handleMapToolClick(eventName, eventArgs) {
      // 显示地图轨迹明细
      if (eventName === 'showMapTrackDetail') {
        this.mapPageParams.showMapTrackDetail = eventArgs
      } else if (eventName === 'refreshData') {
        this.getData()
      }
    },
    handleHistoryTrackSearch(eventArgs) {
      console.log('handleHistoryTrackSearch', eventArgs)
      if (!this.currentSelectedId) {
        this.$warningEx('请先选中工牌')
        return
      }
      // 暂停轨迹播放
      this.handleMapTrackPlayStop()
      this.loading = true
      const queryParam = {}
      queryParam.startTime = eventArgs.startTime
      queryParam.endTime = eventArgs.endTime
      queryParam.deviceId = this.currentSelectedId
      queryHistoryCoordinates(queryParam).then((response) => {
        const data = response.data
        if (data.length === 0) {
          this.$info({
            title: '提示',
            content: '查询不到数据！'
          })
        }
        this.mapPageParams.historyTrackPointsArray = data.map((p) => {
          return { ...p, speed: Number(p.speed) / 10, lat: p.coordinates[1], lng: p.coordinates[0] }
        })
      })
    },
    // 轨迹播放停止
    handleMapTrackPlayStop() {
      this.$refs.monitorRef.mapTrackPlayStop()
    }
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer)
  }
}
</script>

<style lang="less">
.cleaner {
  .map-page {
    height: 100%;
    .map-control-custom {
      position: absolute;
      top: 0;
      left: 50%;
      width: 250px;
      transform: translateX(-50%);
      text-align: center;
      .content {
        height: 50px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        span:first-child {
          font-size: 30px;
          font-weight: 700;
          color: blue;
        }
        span:last-child {
          font-size: 22px;
        }
      }
      .label {
        height: 35px;
        line-height: 35px;
        background: #d7d7d7;
      }
    }
    .map-control-custom-right {
      position: absolute;
      top: 40px;
      right: 0;
      width: 200px;
      .tool {
        background: white;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        -webkit-user-select: none;
        user-select: none;
        i {
          padding-right: 5px;
          color: #1296db;
        }
      }
    }
  }
}
</style>
